import {
  Number,
  Record as RRecord,
  Static,
  String,
  Undefined,
  Union,
} from "runtypes";

import {
  CUSTOM_ERROR_MIMETYPE,
  DISPLAY_TABLE_MIMETYPE,
  DisplayMimeType,
  FILLED_DYNAMIC_VALUE_MIMETYPE,
  HEX_CHART_MIMETYPE,
  MAP_FILLED_DYNAMIC_VALUE_MIMETYPE,
  METRIC_CELL_MIMETYPE,
  MetadataMimeType,
  OUTPUTS_TRUNCATED_MIMETYPE,
  OVERSIZE_OUTPUT_MIMETYPE,
  PIVOT_TABLE_CONFIG_MIMETYPE,
  RICH_TEXT_CELL_MIMETYPE,
  SCOPE_ERROR_MIMETYPE,
  SQL_COMPILED_QUERY_MIMETYPE,
  SQL_STATUS_MIMETYPE,
  STATE_SLICE_MIMETYPE,
  WRITEBACK_STATUS_MIMETYPE,
  isDisplayMimeType,
  isMetadataMimeType,
} from "./mimeTypes";

const MAX_IMAGE_SIZE = 1024 * 1024 * 10; // 10MB
const MAX_CHART_SIZE = 1024 * 1024 * 50; // 50MB
const MAX_HTML_SIZE = 1024 * 1024 * 10; // 10MB
const MAX_TEXT_SIZE = 1024 * 512; // 512KB

// Keep in sync with @hex/python-kernel-startup/python_kernel_startup/formatters.py
const DEFAULT_MAX_OUTPUT_SIZE = 1024 * 512; // 512KB

const MAX_OUTPUT_SIZES: Record<DisplayMimeType | MetadataMimeType, number> = {
  "image/bmp": MAX_IMAGE_SIZE,
  "image/png": MAX_IMAGE_SIZE,
  "image/jpeg": MAX_IMAGE_SIZE,
  "image/gif": MAX_IMAGE_SIZE,
  "image/svg+xml": 1024 * 1024 * 2, // 2MB
  "application/vnd.plotly.v1+json": MAX_CHART_SIZE,
  "application/vnd.vega.v2+json": MAX_CHART_SIZE,
  "application/vnd.vega.v3+json": MAX_CHART_SIZE,
  "application/vnd.vega.v4+json": MAX_CHART_SIZE,
  "application/vnd.vega.v5+json": MAX_CHART_SIZE,
  "application/vnd.vegalite.v1+json": MAX_CHART_SIZE,
  "application/vnd.vegalite.v2+json": MAX_CHART_SIZE,
  "application/vnd.vegalite.v3+json": MAX_CHART_SIZE,
  "application/vnd.vegalite.v4+json": MAX_CHART_SIZE,
  "application/vnd.vegalite.v5+json": MAX_CHART_SIZE,
  "text/html": MAX_HTML_SIZE,
  "text/markdown": MAX_TEXT_SIZE,
  "text/plain": MAX_TEXT_SIZE,
  traceback: 1024 * 1024 * 1, // 1MB

  // Custom hex types
  [DISPLAY_TABLE_MIMETYPE]: 1024 * 1024 * 5, // 5MB
  [OUTPUTS_TRUNCATED_MIMETYPE]: MAX_TEXT_SIZE,
  [OVERSIZE_OUTPUT_MIMETYPE]: MAX_TEXT_SIZE,
  [METRIC_CELL_MIMETYPE]: MAX_TEXT_SIZE,
  [RICH_TEXT_CELL_MIMETYPE]: MAX_TEXT_SIZE,
  // We really don't want people creating 50MB charts, and since this is a new mimetype,
  // we can set a lower limit without breaking apps
  [HEX_CHART_MIMETYPE]: 1024 * 1024 * 10, // 10MB
  [CUSTOM_ERROR_MIMETYPE]: 1024 * 1024 * 1, // 1MB (same as traceback)

  // Max metadata output sizes
  [FILLED_DYNAMIC_VALUE_MIMETYPE]: 1024 * 1024 * 5, // 5MB
  [MAP_FILLED_DYNAMIC_VALUE_MIMETYPE]: 1024 * 1024 * 25, // 25MB
  [SQL_STATUS_MIMETYPE]: 1024 * 5, // 5KB
  [WRITEBACK_STATUS_MIMETYPE]: 1024 * 5, // 5KB
  [SQL_COMPILED_QUERY_MIMETYPE]: 1024 * 128, // 128KB
  [SCOPE_ERROR_MIMETYPE]: 1024 * 5, // 5KB
  [PIVOT_TABLE_CONFIG_MIMETYPE]: 1025 * 32, // 32KB
  [STATE_SLICE_MIMETYPE]: 1024 * 1024 * 5, // 5MB
};
export const getMaxOutputSize = (mimeType: string): number => {
  if (isDisplayMimeType(mimeType) || isMetadataMimeType(mimeType)) {
    return MAX_OUTPUT_SIZES[mimeType];
  } else {
    return DEFAULT_MAX_OUTPUT_SIZE;
  }
};

export const OversizeOutputContents = RRecord({
  mimeType: Union(String, Undefined),
  size: Number,
  sizeLimit: Number,
});
export type OversizeOutputContents = Static<typeof OversizeOutputContents>;

export const MAX_NON_METADATA_OUTPUT_COUNT = 20;
export const MAX_METADATA_OUTPUT_COUNT = 10;

export const OutputsTruncatedContents = RRecord({
  totalOutputs: Number,
});
export type OutputsTruncatedContents = Static<typeof OutputsTruncatedContents>;
