import {
  Array,
  Boolean,
  Dictionary,
  Literal,
  Null,
  Number,
  Record as RRecord,
  Static,
  String,
  Union,
  Unknown,
} from "runtypes";

import { getNormalEnum } from "../runtypeEnums";

export const AnthropicModelLiteral = Union(
  Literal("CLAUDE_SONNET_35"),
  Literal("CLAUDE_3_OPUS"),
);
export type AnthropicModel = Static<typeof AnthropicModelLiteral>;
export const AnthropicModel = getNormalEnum(AnthropicModelLiteral);

export const AnthropicModelNameMap: Record<AnthropicModel, string> = {
  [AnthropicModel.CLAUDE_SONNET_35]: "claude-3-5-sonnet-20240620",
  [AnthropicModel.CLAUDE_3_OPUS]: "claude-3-opus-20240229",
};

export const AnthropicModelParams = RRecord({
  model: AnthropicModelLiteral,
  maxTokens: Number,
  stopSequences: Array(String),
  temperature: Number,
  topK: Number,
  topP: Number,
});
export type AnthropicModelParams = Static<typeof AnthropicModelParams>;

export const AnthropicMessageRoleLiteral = Union(
  Literal("user"),
  Literal("assistant"),
);
export type AnthropicMessageRole = Static<typeof AnthropicMessageRoleLiteral>;
export const AnthropicMessageRole = getNormalEnum(AnthropicMessageRoleLiteral);

export const TextBlock = RRecord({
  type: Literal("text"),
  text: String,
});
export type TextBlock = Static<typeof TextBlock>;

export const ToolUseBlock = RRecord({
  type: Literal("tool_use"),
  id: String,
  name: String,
  input: Dictionary(Unknown, String),
});
export type ToolUseBlock = Static<typeof ToolUseBlock>;

export const ToolResultBlock = RRecord({
  type: Literal("tool_result"),
  tool_use_id: String,
  content: Array(TextBlock),
});
export type ToolResultBlock = Static<typeof ToolResultBlock>;

export const AnthropicMessage = RRecord({
  role: AnthropicMessageRoleLiteral,
  content: Array(Union(TextBlock, ToolUseBlock, ToolResultBlock)),
});
export type AnthropicMessage = Static<typeof AnthropicMessage>;

export const AnthropicMessages = RRecord({
  systemMessage: String,
  userAndAssistantMessages: Array(AnthropicMessage),
});
export type AnthropicMessages = Static<typeof AnthropicMessages>;

export const AnthropicConformerResult = RRecord({
  messages: AnthropicMessages,
  totalContextTokens: Number.Or(Null),
  usedTokenCountApproximation: Boolean,
});
export type AnthropicConformerResult = Static<typeof AnthropicConformerResult>;

export const AnthropicErrorPayload = RRecord({
  type: Literal("error"),
  error: RRecord({
    type: String,
    message: String,
  }),
});
export type AnthropicErrorPayload = Static<typeof AnthropicErrorPayload>;
