import {
  Array,
  Boolean,
  Null,
  Number,
  Record,
  Static,
  String,
  Tuple,
  Union,
} from "runtypes";

import { DatetimeValue } from "./datetimeType";
import { FileTypeLiteral } from "./enums.js";
import { AppSessionFileId, FileId } from "./idTypeBrands.js";
import { TableInputData } from "./tableInputTypes";
import { JsonSerializedParameterValueContents } from "./typeBrands";

export const AllowedDynamicListValueTypes = Union(
  String,
  Number,
  Boolean,
  Null,
);
export type AllowedDynamicListValueTypes = Static<
  typeof AllowedDynamicListValueTypes
>;

export const DynamicList = Array(AllowedDynamicListValueTypes);
export type DynamicList = Static<typeof DynamicList>;

export const StringArray = Array(String);
export type StringArray = Static<typeof StringArray>;

export const NumberArray = Array(Number);
export type NumberArray = Static<typeof NumberArray>;

export const DatetimeValueTuple = Tuple(DatetimeValue, DatetimeValue);
export type DatetimeValueTuple = Static<typeof DatetimeValueTuple>;

export const FileUpload = Record({
  id: Union(FileId, AppSessionFileId),
  name: String,
  type: FileTypeLiteral,
});
export type FileUpload = Static<typeof FileUpload>;

export const ParameterValueContents = Union(
  String,
  Boolean,
  Null,
  Number,
  StringArray,
  NumberArray,
  DynamicList,
  TableInputData,
  DatetimeValue,
  DatetimeValueTuple,
  FileUpload,
);
export type ParameterValueContents = Static<typeof ParameterValueContents>;

export const parseJsonSerializedParameterValueContents = (
  contents: JsonSerializedParameterValueContents,
): ParameterValueContents => {
  try {
    return JSON.parse(contents);
  } catch (err) {
    console.error(err);
    throw new Error(`Invalid serialized parameter value`);
  }
};

export const serializeParameterValueContents = (
  contents: ParameterValueContents,
): JsonSerializedParameterValueContents => {
  return JSON.stringify(contents) as JsonSerializedParameterValueContents;
};
