import { HexType, ProjectLanguage } from "./enums";
import { DataConnectionId, StaticCellId, VcsPackageId } from "./idTypeBrands";

type ImportWarnings = {
  status?: string | null;
  categories?: readonly string[] | null;
  projectSecrets?: readonly string[] | null;
  sharedSecrets?: readonly string[] | null;
  projectDataConnections?: readonly DataConnectionId[] | null;
  sharedDataConnections?: readonly DataConnectionId[] | null;
  sharedVcsPackages?: readonly VcsPackageId[] | null;
  projectTitle?: string | null;
  projectLanguage?: ProjectLanguage | null;
  cellIds?: readonly StaticCellId[] | null;
  appLayout?: readonly string[] | null;
  timezones?: ReadonlyArray<string> | null;
  exportedHexType?: HexType | null;
};

export const checkForWarnings = (
  warnings: ImportWarnings | undefined,
): boolean => {
  return (
    !!warnings &&
    (!!warnings.status ||
      (warnings.categories ?? []).length > 0 ||
      (warnings.projectSecrets ?? []).length > 0 ||
      (warnings.sharedSecrets ?? []).length > 0 ||
      (warnings.projectDataConnections ?? []).length > 0 ||
      (warnings.sharedDataConnections ?? []).length > 0 ||
      (warnings.sharedVcsPackages ?? []).length > 0 ||
      !!warnings.projectTitle ||
      !!warnings.projectLanguage ||
      (warnings.cellIds ?? []).length > 0 ||
      (warnings.appLayout ?? []).length > 0 ||
      (warnings.timezones ?? []).length > 0 ||
      !!warnings.exportedHexType)
  );
};
