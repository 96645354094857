/**
 * Due to limitations in the DuckDB extension system (time zones support is
 * implemented as an extension), casting TIMESTAMPTZ -> DATE is not supported.
 * Actually we use a patched version of DuckDB that supports this to avoid
 * breaking user queries, but the cast does not respect time zone.
 *
 * This function produces a DuckDB expression that will convert a TIMESTAMPTZ
 * to DATE while respecting time zone.
 */
export function timestamptzToDate(timestamptz: string): string {
  // SUP-408: Previously we used the more efficient expression
  //   make_date(date_part(['year', 'month', 'day'], ${timestamptz}))
  // But it is bugged https://github.com/duckdb/duckdb/issues/5342
  // TODO (dscott): revert to the more efficient expression once fixed
  return `strftime(${timestamptz}, '%Y-%m-%d')::date`;
}
