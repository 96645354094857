import { groupBy, keyBy } from "lodash";

export type SingletonOrArray<G> = G | G[];

/* eslint-disable tree-shaking/no-side-effects-in-initialization */
/*
 * Helper functions to correctly type the return types of Object.keys and Object.entries
 * now that typescript 4.4 supports type-branded strings/symbols in Records.
 */

type ObjectKey = string | number | symbol;

// eslint-disable-next-line @typescript-eslint/ban-types
export const typedObjectKeys = <Obj extends object>(
  obj: Obj,
): Array<keyof Obj> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return Object.keys(obj) as any;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const typedObjectValues = <Obj extends object>(
  obj: Obj,
): Array<Obj[keyof Obj]> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return Object.values(obj) as any;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const typedObjectEntries = <Obj extends object>(
  obj: Obj,
): { [K in keyof Obj]: [K, Obj[K]] }[keyof Obj][] => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return Object.entries(obj) as any;
};

export const typedObjectFromEntries = <
  Entries extends readonly (readonly [ObjectKey, unknown])[],
>(
  entries: Entries | Iterable<Entries[number]>,
): {
  [Tup in Entries[number] as Tup[0]]: Tup[1];
} => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return Object.fromEntries(entries) as any;
};

export const typedRecordFromEntries = <Key extends string, T>(
  entries: Iterable<readonly [Key, T]>,
): Record<Key, T> => {
  return Object.fromEntries(entries) as Record<Key, T>;
};

export const typedGetOwnPropertyNames = <Obj extends object>(
  obj: Obj,
): Array<keyof Obj> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- needed to correctly type property names as keys
  return Object.getOwnPropertyNames(obj) as any;
};

export const typedKeyGroupBy = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Obj extends Record<K, any>,
  K extends keyof Obj,
>(
  collection: Obj[],
  key: K,
): Record<Obj[K], Obj[] | undefined> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return groupBy(collection, key) as any;
};

export const typedKeyBy = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Obj extends Record<K, any>,
  K extends keyof Obj,
>(
  collection: Obj[],
  key: K,
): Record<Obj[K], Obj | undefined> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return keyBy(collection, key) as any;
};
