/* eslint-disable tree-shaking/no-side-effects-in-initialization */
import {
  Array,
  Boolean,
  Null,
  Number,
  Optional,
  Partial,
  Record as RRecord,
  Static,
  String,
  Unknown,
} from "runtypes";

export const TableStoreReference = RRecord({
  contentHash: String,
  versionId: String,
});
export type TableStoreReference = Static<typeof TableStoreReference>;

export const SqlCacheMetadata = RRecord({
  timestamp: String,
  source: Optional(String),
  cacheHit: Optional(Boolean),
  key: Optional(String),
  tableRef: Optional(TableStoreReference),
});
export type SqlCacheMetadata = Static<typeof SqlCacheMetadata>;

export const SqlStatus = RRecord({
  finished: Boolean,
  loadedRows: Number,
  loadedBytes: Number.Or(Null),
}).And(
  Partial({
    currentBatchRows: Number,
    message: String.Or(Null),
    numColumns: Number,
    elapsedTime: Number,
    metadata: SqlCacheMetadata.Or(Null),
  }),
);
export type SqlStatus = Static<typeof SqlStatus>;

export const SqlCompiledQuery = RRecord({
  // may be truncated if overly long
  query: String,
  // the length of the query if it weren't truncated
  queryLength: Number,
  // may be truncated if overly long
  parameters: Array(Unknown),
  // the number of parameters if they weren't truncated
  parametersLength: Number,
});
export type SqlCompiledQuery = Static<typeof SqlCompiledQuery>;

export const WritebackStatus = RRecord({
  message: String.Or(Null),
  writtenRows: Number,
  totalRows: Number,
  elapsedTime: Number,
  writtenBytes: Number,
  totalBytes: Number,
});

export type WritebackStatus = Static<typeof WritebackStatus>;
